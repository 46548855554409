import React from 'react'
import { graphql } from 'gatsby'

import styled from '@emotion/styled'
import { Jumbotron, Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Vina from '../images/vina.jpg'

import { myContext } from '../../provider'

const Team = styled(Container)`
  margin-top: 3rem;
  background-color: #ee8013;
  color: white;
  padding: 2rem;
  h1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 1.2rem;
  }
`

const TeamMember = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    padding: 0;
    margin: 0;
  }
`

const MemberPhoto = styled.div`
  width: 200px;
  height: 250px;
  background-color: #979797;
`

const About = ({ data }) => (
  <myContext.Consumer>
    {({ language }) => {
      const nodes = data.allContentfulAboutPage.nodes
      const aboutPageText = nodes.find((node) => node.language === language)

      return (
        <Layout>
          <SEO title="About" />
          <Container>
            <Jumbotron>
              <Col>
                <div style={{ width: '100%', height: 'auto' }}>
                  <img
                    src={Vina}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              </Col>
            </Jumbotron>
          </Container>
          <Container>
            <Row className="justify-content-md-center">
              About Vina Inusrance LTD
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={7}>{aboutPageText.about.about}</Col>
            </Row>
          </Container>
          <Team fluid>
            <Container>
              <Row className="justify-content-md-center">
                <h1>{aboutPageText.meetLabel}</h1>
              </Row>
              <Row>
                {aboutPageText.teamMembers.map(({ name, position, id }) => (
                  <Col xs={12} sm={6} md={4} key={id}>
                    <TeamMember>
                      <MemberPhoto></MemberPhoto>
                      <h2>{name}</h2>
                      {position && <span>{position}</span>}
                    </TeamMember>
                  </Col>
                ))}
              </Row>
            </Container>
          </Team>
        </Layout>
      )
    }}
  </myContext.Consumer>
)

export const pageQuery = graphql`
  query AboutPageQuery {
    allContentfulAboutPage {
      nodes {
        teamMembers {
          name
          id
          position
        }
        language
        heroText {
          heroText
        }
        meetLabel
        about {
          about
        }
      }
    }
  }
`
export default About
